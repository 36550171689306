import "../../../assets/styles/faq.scss";
import {useEffect, useState} from "react";
import axios from "axios";

function Accordion() {

    const [selected, setSelected] = useState(null);
    const [faq, setFaq] = useState([]);
    const [limit, setLimit] = useState(4);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    }
    const showMore = () => {
        setLimit(prevValue => prevValue + 2);
    }
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(window.location.origin + '/fake-faq.json');
                setFaq(response.data);
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        };

        getData();

    }, []);

    return (
        <div className="Accordion">
            <div className="faq-top">
                <div>
                    <h2>Ən çox soruşulanlar</h2>
                    <p>FAQ (Frequently Asked Questions / Ən çox soruşulan suallar) bölməsində istifadəçilərimiz
                        tərəfindən mütəmadi
                        olaraq
                        verilən suallar yerləşdirilib. Siz də e-sayar-la bağlı yaranan sualların
                        cavablandırılması
                        üçün öz suallarınızı bizə yönləndirə bilərsiniz! </p>
                </div>
            </div>
            {
                
                faq.slice(0, limit).map((item, i) => (
                    <div key={item.number} className="item">

                        <span className="number">{item.number}</span>

                        <div className="texts">
                            <div className="title" onClick={() => toggle(i)}>
                                <h2>{item.question}</h2>
                                <div>{selected === i ? <i className="open fas fa-plus"></i> :
                                    <i className=" fas fa-plus"></i>
                                }</div>
                            </div>
                            <div className={selected === i ? "content show" : "content"}>
                                {item.answer}
                            </div>
                        </div>

                    </div>
                ))
            }
            <button onClick={showMore}>Daha çox yüklə <i className="fas fa-angle-down"></i></button>

        </div>
    );
}


export default Accordion;