import "../../../assets/styles/about/aboutus.scss";
import {tariffLeftAnimation, tariffRightAnimation, overlayAnimation, topContainerAnimation} from "../../../Animation";
import {motion} from "framer-motion";
import {useScroll} from "../useScroll";

function Aboutus(props) {

    return (
        <>

            <div className="About-us">
                <div className="about-container">
                    <div className="left">
                        <span>Biz kimik</span>
                        <h3 className="title">Haqqımızda</h3>
                        <p className="blog-post-text">e-sayar.az(eləcə də esayar.az) portalı mühasibat uçotu üzrə
                            e-sayar
                            kompüter proqramı bazasında yaradılmışdır və kredit təşkilatları istisna olmaqla, kommersiya
                            təşkilatlarına, habelə hüquqi şəxs yaratmadan sahibkarlıq fəaliyyəti ilə məşğul olan fiziki
                            şəxslərə (mühasibat uçotu subyekti) elektron məlumat daşıyıcıları vasitəsi ilə
                            Maliyyə Hesabatlarının Beynəlxalq Standartlarına uyğun mühasibat uçotu registrlərini tərtib
                            etməyə imkan verir. e-sayar.az portalı və mühasibat uçotu üzrə e-sayar kompüter proqramı
                            vahid
                            bir innovasiya layihəsidir. e-sayar.az portalı və e-sayar kompüter proqramı əqli mülkiyyət
                            hüququnun obyektidir.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Aboutus;