import React from "react";

function Btn() {
  return (
    <a
      class="text-button strong"
      href="https://www.youtube.com/watch?v=GSbsNA2djDE&list=PLiQQCjHvXGpBuXtk81uc_DEuH3UfvH4PI"
    >
      Təlimata keçid
      <svg
        class="hover-arrow cta"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path class="arrow-path" d="M7 5H1" />
        <path class="arrow-tip" d="M5 1L9 5L5 9" />
      </svg>
    </a>
  );
}

export default Btn;
