import "../../assets/styles/navbar.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

function Navbar(props) {
    const [navbar, setNavbar] = useState(false);
    const [menu, setMenu] = useState(false);
    const [navLink, setNavLink] = useState(1);


    console.log(window.location.href)

    const changeLink = (index) => {
        setNavLink(index);
        setMenu(false);
    }

    return (
        <>
            <div className={navbar ? "Navbar navbar-fixed" : "Navbar"}>
                <div className="nav-container">
                    <a href="https://www.e-sayar.az/">
                        <img src="assets/img/Logo-white.svg" alt="" className="logo" />
                    </a>

                    <div className={"nav-links " + (menu && "active ") + (navbar && " sidebar-color")}>


                        <ul className="nav-links-list">

                            <a onClick={() => changeLink(1)}
                                className="effect-underline link"
                                href="https://www.e-sayar.az/#main">Əsas səhifə</a>

                            <a onClick={() => changeLink(2)}
                                className="link effect-underline"
                                href="https://www.e-sayar.az/#ustunlukler">Üstünlüklərimiz</a>

                            <a onClick={() => changeLink(3)}
                                className="link effect-underline"
                                href="https://www.e-sayar.az/#about">Haqqımızda</a>

                            <Link onClick={() => changeLink(8)}
                                className="link effect-underline "
                                to="/hesabat">
                                Hesabat
                            </Link>
                            <a
                                onClick={() => changeLink(5)}
                                className="link effect-underline "
                                href="https://www.e-sayar.az/#tarif">Tariflər</a>
                            <a onClick={() => {
                                changeLink(6);
                            }}
                                className="link effect-underline "
                                href={(window.location.href.includes(`/questions`) || window.location.href.includes(`/about`)) ? (window.location.href + "/#contact") : (window.location.origin + "/#contact")}>Əlaqə

                            </a>
                            <Link onClick={() => changeLink(7)}
                                className="link effect-underline "
                                to="/questions">
                                FAQ
                            </Link>
                        </ul>


                        <div className="nav-btns">

                            <a href="https://www.e-sayar.az/admin/login">
                                <button className="login">Daxil ol</button>
                            </a>
                            <a href="https://www.e-sayar.az/admin/register-user-c">
                                <button className="signup">Qeydiyyat</button>
                            </a>
                        </div>
                    </div>

                    <div className="btns">

                        {/*<div id="dropdown">*/}
                        {/*    <button className="lang-az">Az</button>*/}
                        {/*    <button className="lang-ru">Ru</button>*/}
                        {/*</div>*/}


                        <div onClick={() => setMenu(!menu)} className={"menu-btn " + (menu && "open")}>
                            <div className="menu-btn__burger"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
        ;
}

export default Navbar;