import "../../../assets/styles/about/why.scss";
import {reveal} from "../../../Animation";
import {motion} from "framer-motion";
import {useScroll} from "../useScroll";

function Why(props) {

    return (
        <div className="why">
            <motion.div className="why-container" >

                <h2>Nə üçün e-sayar?</h2>

                <div className="text-container">

                    <ul>
                        <li>Uçot siyasətinin müəyyən edilməsi sərbəstliyi;</li>
                        <li>İcmal (konsalidə olunmuş) hesabatın
                            hazırlanma imkanı;
                        </li>
                        <li>İstifadəçilərin səlahiyyət bölgüsü;</li>
                        <li>Mühasibat uçotu xərcinin minimuma endirilməsi;</li>
                        <li>Geniş axtarış, analitik uçot üçün çeşidləmə və ya ümumiləşdirmə imkanı;</li>
                    </ul>

                    <ul>
                        <li>Mühasibat əməliyyatlarının işlənməsi üçün
                            əllə daxil edilən məlumatların minimum olması;
                        </li>
                        <li>Mühabirləşmələrin avtomatik icra olunması.</li>
                        <li>İstənilən hesab üzrə aparılmış əməliyyatların
                            dövriyyə məlumatlarının əldə edilməsi;
                        </li>
                        <li>Real vaxt rejimində, istənilən dövr üçün
                            maliyyə hesabatlarının əldə edilməsinin mümkünlüyü
                        </li>
                        <li>Avtomatik yadda saxlama və arxivləşdirmə</li>
                    </ul>
                </div>
            </motion.div>
        </div>
    );
}

export default Why;