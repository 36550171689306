import "../../assets/styles/footer.scss";
import { Link } from "react-router-dom";

function Footer(props) {
    return (
        <div className="Footer" id="contact">
            <div className="footer-container">
                <div className="grid grid1">
                    <a href="https://www.e-sayar.az/">
                        <img src="assets/img/Logo-white.svg" alt="" className="logo" />

                    </a>
                    <p className="motto">Sizin mühasib dostunuz!</p>
                    <div className="socials">
                        <a href="https://www.linkedin.com/company/e-sayar">
                            <i className="social-icon fab fa-linkedin-in"></i>
                        </a>

                        <a href="https://www.facebook.com/esayar.az/">
                            <i className="social-icon fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com/esayar.az/">
                            <i className="social-icon fab fa-instagram"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC-Gv1KFvR1iIY4NZ_QJMJlA">
                            <i className="social-icon fa-brands fa-youtube"></i>
                        </a>
                    </div>


                </div>
                <div className="grid grid2">
                    <h2>Keçidlər</h2>
                    <ul className="links">
                        <a href="https://www.e-sayar.az/#main">
                            <li className="link">Ana səhifə</li>
                        </a>
                        <a href="https://www.e-sayar.az/#ustunlukler">
                            <li className="link">Üstünlüklərimiz</li>
                        </a>
                        <a href="https://www.e-sayar.az/#about">
                            <li className="link">Haqqımızda</li>
                        </a>
                        <Link
                            to="/hesabat">
                             <li className="link">Hesabat</li>
                        </Link>
                        <a href="https://www.e-sayar.az/#tarif">
                            <li className="link">Tariflər</li>
                        </a>
                        <a
                            href={(window.location.href.includes(`/questions`) || window.location.href.includes(`/about`)) ? (window.location.href + "/#contact") : (window.location.origin + "/#contact")}>
                            <li className="link">Əlaqə</li>
                        </a>
                        <Link to="/questions">
                            <li className="link">FAQ</li>
                        </Link>
                    </ul>
                </div>
                <div className="grid grid3">
                    <h2>İmkanlarımız</h2>
                    <ul className="links">
                        <li className="link">Mühasibat uçotu</li>
                        <li className="link">Mühasibat uçotunun auditi</li>
                        <li className="link">Rəhbər hesabatı</li>
                        <li className="link">Mühasibatlığınızın təhlil və analizi</li>
                        <li className="link">7/24 konsultasiya</li>
                    </ul>
                </div>
                <div className="grid grid4">
                    <h2>Əlaqə</h2>
                    <div className="contacts">
                        <div className="contact-item">
                            <i className="contact-icon fas fa-map-marker-alt"></i>
                            <a target="_blank" href="https://goo.gl/maps/t4vtjQ9vgLCA98jK7" className="contact-info">Bakı,
                                Heydər
                                Əliyev prospekti, 64</a>
                        </div>
                        <div className="contact-item">
                            <i className="contact-icon fas fa-phone-alt"></i>
                            <a href="tel:+994552992254" className="contact-info">+994552992254</a>
                        </div>
                        <div className="contact-item">
                            <img className="contact-icon-img" src="assets/img/office-phone-50.svg" alt="" />
                            <a href="tel:+994125144447" className="contact-info">+994125144447</a>
                        </div>
                        <div className="contact-item">
                            <i className="contact-icon fas fa-envelope-open-text"></i>
                            <a href="mailto: info@e-sayar.az" className="contact-info">info@e-sayar.az</a>
                        </div>
                    </div>
                </div>
            </div>
            <p className="copyright">Bütün hüquqlar qorunur. &copy; {(new Date().getFullYear())} e-sayar.</p>
        </div>
    );
}

export default Footer;