import React, {useState} from 'react';
import axios from "axios";

function ApplicationForm(props) {
    const url = "";
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(url, {
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message
        }).then((response) => {
            alert("Sualınız göndərildi.");
            resetForm()
        }).catch(() => alert("Sualınızı göndərmək mümkün olmadı.")
        )
    }
    const handleForm = (e) => {
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        // console.log(newData)
    }
    const resetForm = () => {
        setData({name: "", email: "", subject: "", message: ""})
    }
    return (
        <div className="application-container">
            <div className="Application">
                <div className="form-text">
                    <i className="form-icon fas fa-question-circle"></i>
                    <h2>Əlavə sualınız olduğu halda, bizə müraciət ede bilərsiniz</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="inputs">
                        <div className="form-input">
                            <input onChange={handleForm} id="name" value={data.name} type="text"
                                   placeholder="Ad"/>
                            <i className="icon fas fa-user"></i>
                        </div>

                        <div className="form-input">
                            <input onChange={handleForm} id="email" value={data.email} type="email"
                                   placeholder="E-poçt"/>
                            <i className="icon fas fa-envelope-open-text"></i>
                        </div>
                    </div>

                    <textarea onChange={handleForm} id="subject" value={data.subject} name=""
                              placeholder="Mövzu*"></textarea>
                    <textarea className="mesaj" onChange={handleForm} id="message" value={data.message} name=""
                              placeholder="Mesaj*"></textarea>
                    <button type="submit" className="send">Göndər</button>
                </form>
            </div>
        </div>
    );
}

export default ApplicationForm;