import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

function Calc() {
    const docs = [
        { uri: require("../../assets/h.pdf") }, // Local File
    ];

    return <DocViewer pluginRenderers={DocViewerRenderers} documents={docs}
        config={{
            header: {
                disableHeader: false,
                disableFileName: false,
                retainURLParams: false
            }
        }}
        style={{ height: 900 }}
    />;

}
export default Calc;
