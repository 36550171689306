import "../../assets/styles/about/about.scss";
import Aboutus from "../../components/Front/About/Aboutus";
import Why from "../../components/Front/About/Why";
import How from "../../components/Front/About/How";
import Footer from "../../components/Front/Footer";
import Navbar from "../../components/Front/Navbar";

function About(props) {
  return (
    <>
      <Navbar />
      <div className="About">
        <div className="container">
          <Aboutus />
          <Why />
          <How />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default About;
