import "../../../assets/styles/home/ustunlukler.scss";
import {motion} from "framer-motion";
import {reveal} from "../../../Animation";
import {useScroll} from "../useScroll";

function Ustunlukler() {
    const [element, controls] = useScroll();
    const isMobile = window.innerWidth > 768;


    return (
        <div ref={element} className="Ustunlukler" id="ustunlukler">
                <h2>Üstünlüklər</h2>
                <motion.div className="grid-container"
                            animate={controls}
                            variants={isMobile ? reveal : null}
                            transition={{delay: 0.1, duration: 0.4}}
                >
                    <div className="grid-item">
                        <i className="icon fas fa-tachometer-alt"></i>
                        <p className="text">Yüksək
                            hesablama
                            sürəti</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon fas fa-globe"></i>
                        <p className="text">Beynəxalq standartlara
                            uyğun mühasibat</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon fas fa-users"></i>
                        <p className="text">Komanda işinin
                            dəstəklənməsi</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon far fa-object-group"></i>
                        <p className="text">Rahat inteqrasiya
                            imkanları</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon far fa-file-alt"></i>
                        <p className="text">İcmal hesabatının
                            hazırlanması</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon fas fa-table"></i>
                        <p className="text">Balans və dövriyyə
                            cədvəlinin hazırlanması</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon fas fa-shapes"></i>
                        <p className="text">Müxtəlif sahələrə
                            adaptasiya rahatlığı</p>
                    </div>
                    <div className="grid-item">
                        <i className="icon fas fa-sticky-note"></i>
                        <p className="text">Sənədlərin tez bir zamanda
                            əldə edilməsi</p>
                    </div>
                </motion.div>
            </div>
    );
}

export default Ustunlukler;