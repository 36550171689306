import "../../../assets/styles/about/how.scss";
import {cardAnimation, reveal, tariffLeftAnimation} from "../../../Animation";
import {motion} from "framer-motion";
import {useScroll} from "../useScroll";

function How(props) {
    const [element, controls] = useScroll();

    return (
        <>
            <div className="How">
                <div className="how-container">
                    <div className="left">

                        <i className="icon fas fa-question-circle"></i>

                        <div className="text">
                            <h3>e-sayar necə işləyir?</h3>
                            <p>
                                Çox sadə! Mühasibat uçotunun aparılması üçün əsas olan ilkin mühasibat sənədlərindəki
                                məlumatları
                                e-sayarın müvafiq əməliyyat növlərinə aid bölmələrinə doldur, bitdi! Qalan işi e-sayar
                                özü
                                yerinə
                                yetirəcəkdir! e-sayar Sizin əvəzinizdən dövriyyə balansında, baş kitabda və maliyyə
                                (gəlir
                                və
                                xərclər) hesabatında lazımi mühabirləşmələri avtomatik icra edəcək, cari balansı
                                yeniləyəcəkdir.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default How;