import Header from "../../components/Front/Home/Header";
import Ustunlukler from "../../components/Front/Home/Ustunlukler";
import Haqqimizda from "../../components/Front/Home/Haqqimizda";
import Xidmetler from "../../components/Front/Home/Xidmetler";
import Calc from "../../pages/Front/Calc";
import Tarifler from "../../components/Front/Home/Tarifler";
import { motion } from "framer-motion";
import "../../app.scss";
import Footer from "../../components/Front/Footer";
import Navbar from "../../components/Front/Navbar";

function Home() {
  return (
    <>
      <Navbar />
      <motion.div className="App" initial="hidden" animate="show">
        <div className="sections">
          <Header />
          <Ustunlukler />
          <Haqqimizda />
          <Xidmetler />
          <Tarifler />
          <Footer />
        </div>
      </motion.div>
    </>
  );
}

export default Home;
