import "../../../assets/styles/home/header.scss";
import Navbar from "../Navbar";
import { headerAnimation, ImageAnimation } from "../../../Animation";
import { motion } from "framer-motion";
import { useScroll } from "../useScroll";
import Btn from "../../Btn";

function Header() {
  const [element, controls] = useScroll();
  return (
    <>
      <div ref={element} className="Header" id="main">
        <div className="container">
          <motion.div
            className="left"
            variants={headerAnimation}
            animate={controls}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <h2>e-sayar</h2>
            <p>
              “e-sayar.az” mühasibatlıq proqramı ilə mühasibatlığınızı istənilən
              yerdə və istədiyiniz zaman rahatlıqla idarə edin!
            </p>
            <Btn />
          </motion.div>
          <motion.div
            className="image"
            variants={ImageAnimation}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            {/*<div className="cards">*/}
            {/*    <div className="card card1">*/}
            {/*        <div className="icon-container">*/}
            {/*            <i className="icon fas fa-calculator"></i>*/}
            {/*        </div>*/}
            {/*        <p className="card-text">Mühasibat uçotu</p>*/}

            {/*    </div>*/}

            {/*    <div className="card card2">*/}
            {/*        <div className="icon-container">*/}
            {/*            <i className="icon far fa-comments"></i>*/}
            {/*        </div>*/}
            {/*        <p className="card-text">7/24 Konsultasiya</p>*/}

            {/*    </div>*/}
            {/*    <div className="card card3">*/}
            {/*        <div className="icon-container">*/}
            {/*            <i className="icon fas fa-users-cog"></i>*/}
            {/*        </div>*/}
            {/*        <p className="card-text">Mühasibat uçotu*/}
            {/*            auditi</p>*/}

            {/*    </div>*/}
            {/*    <div className="card card4">*/}
            {/*        <div className="icon-container">*/}
            {/*            <i className="icon fas fa-users"></i>*/}
            {/*        </div>*/}
            {/*        <p className="card-text">Rəhbər hesabatı</p>*/}
            {/*    </div>*/}
            {}
            {/*</div>*/}
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Header;
