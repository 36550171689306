import About from "./pages/Front/About";
import Home from "./pages/Front/Home";
import Calc from "./pages/Front/Calc";
import Faq from "./pages/Front/Faq";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
// import Back from "./layout/Back";
// import Error from "./pages/Error";
// import Login from "./pages/Front/Login";
// import Register from "./pages/Front/Register";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  return (
    <Router>
      <>
        <ScrollToTop />

        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/questions" element={<Faq />} />
          <Route path="/hesabat" element={<Calc />} />

          {/*<Route path="login" element={<Login/>}/>*/}
          {/*<Route path="register" element={<Register/>}/>*/}

          {/*<Route path="/admin" element={<Back/>}>*/}
          {/*    <Route index/>*/}
          {/*    <Route path=""/>*/}
          {/*</Route>*/}
          {/*<Route path="*" element={<Error/>}/>*/}
        </Routes>
      </>
    </Router>
  );
}

export default App;
