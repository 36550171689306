import "../../../assets/styles/home/haqqimizda.scss";
import {Link} from "react-router-dom";
import {tariffLeftAnimation, tariffRightAnimation, overlayAnimation} from "../../../Animation";
import {motion} from "framer-motion";
import {useScroll} from "../useScroll";

function Haqqimizda() {
    const [element, controls] = useScroll();
    const isMobile = window.innerWidth > 820;

    return (
        <div ref={element} className="Haqqimizda" id="about">
            <div className="container">
                <motion.div className="left"
                            variants={isMobile ? tariffLeftAnimation : null}
                            animate={controls}
                            transition={{duration: 0.6}}
                >
                    <h2>Haqqımızda</h2>
                    <p className="about">e-sayar.az (eləcə də esayar.az) portalı mühasibat uçotu üzrə e-sayar Kompüter proqramının bazasında yaradılmışdır və kredit təşkilatları istisna olmaqla, kommersiya
                        təşkilatlarına, habelə hüquqi şəxs yaratmadan sahibkarlıq fəaliyyəti ilə məşğul olan fiziki
                        şəxslərə (mühasibat uçotu subyekti) elektron məlumat daşıyıcıları vasitəsi ilə
                        Maliyyə Hesabatlarının Beynəlxalq Standartlarına uyğun mühasibat uçotu registrlərini tərtib
                        etməyə imkan verir.

                        e-sayar.az portalı və mühasibat uçotu üzrə e-sayar kompüter proqramı vahid bir innovasiya
                        layihəsidir.

                        e-sayar.az portalı və e-sayar kompüter proqramı əqli mülkiyyət hüququnun obyektidir. </p>
                </motion.div>
                <motion.div className="right"
                            variants={isMobile ? overlayAnimation : null}
                            animate={controls} transition={{duration: 0.8}}
                >
                    <div className="overlay">
                        <h2>Nə üçün e-sayar?</h2>
                        <ul>
                            <li>Proqramın dilinin başa düşülən olması</li>
                            <li>Əməliyyat icrasının sadəliyi</li>
                            <li>İnternetin olduğu hər yerdə əlçatan olması</li>
                            <li>Məxfilik</li>
                            <li>Avtomatik yadda saxlama və arxivləşdirmə</li>
                        </ul>
                        <Link to="/about">
                            <button className="btn-about">Daha ətraflı</button>
                        </Link>
                    </div>

                </motion.div>
            </div>
        </div>
    )
        ;
}

export default Haqqimizda
;