import "../../../assets/styles/home/xidmetler.scss"
import {useScroll} from "../useScroll";
import {motion} from "framer-motion";
import {cardAnimation} from "../../../Animation";

function Xidmetler() {
    const [element, controls] = useScroll();
    const isMobile = window.innerWidth > 580;

    return (
        <div className="Xidmetler" id="xidmet">
            <div className="container">
                <h2>İmkanlar </h2>

                <div ref={element} className="grid-container">
                    <motion.div className="grid-item item1"
                                animate={controls}
                                variants={isMobile ? cardAnimation : null}
                                transition={{duration: 0.5}}
                    >
                        <div className="item-overlay">
                            <h3 className="item-heading">Mühasibat uçotu və auditi</h3>
                            <p className="item-text">Mühasibat uçotunuzun və auditinin aparılması</p>
                        </div>
                    </motion.div>

                    <motion.div className="grid-item item3" animate={controls}
                                variants={isMobile ? cardAnimation : null}
                                transition={{duration: 0.5}}
                    >
                        <div className="item-overlay">
                            <h3 className="item-heading">7/24
                                Konsultasiya</h3>
                            <p className="item-text"> 7/24 e-sayar-la bağlı sorğuların cavablandırılması</p>
                        </div>
                    </motion.div>
                    <motion.div className="grid-item item4" animate={controls}
                                variants={isMobile ? cardAnimation : null}
                                transition={{duration: 0.5}}
                    >
                        <div className="item-overlay">
                            <h3 className="item-heading">Rəhbər hesabatı</h3>
                            <p className="item-text">Rəhbər şəxslər üçün sahibkarlıq etdiyi şirkətlərin toplu
                                hesabatlarının
                                hazırlanması</p>
                        </div>
                    </motion.div>

                    <motion.div className="grid-item item5" animate={controls}
                                variants={isMobile ? cardAnimation : null}
                                transition={{duration: 0.5}}
                    >
                        <div className="item-overlay">
                            <h3 className="item-heading">Mühasibatlığınızın təhlil və analizi</h3>
                            <p className="item-text">Mühasibatlığınızdakı məlumatların ətraflı araşdırılması və
                                təhlili</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Xidmetler;