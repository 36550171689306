import "../../../assets/styles/home/tarifler.scss";
import {useState} from "react";
import {motion} from "framer-motion";

import {useScroll} from "../useScroll";
import {reveal} from "../../../Animation";

function Tarifler() {

    const isMobile = window.innerWidth > 1048;

    const [element, controls] = useScroll();

    const [sahibkar, setSahibkar] = useState(false);
    return (
        <div ref={element} className="Tarifler" id="tarif">
            <div className="tarif-container">
                <h2>Tariflər</h2>
                <p className="tariffs">Sahibkarlıq subyektinizin ölçüsünü qeyd edərək, e-sayar-ın sizin üçün təyin
                    etdiyi tariflərlə tanış olun! e-sayar-da qeydiyyatdan keçərək aşağıda qeyd olunan funksiyalardan
                    yararlana biləcəksiniz:

                </p>
                <ul className="funksiyalar">
                    <li>İlkin qalıqlar</li>
                    <li>Dövriyyə</li>
                    <li>Kontragent</li>
                    <li>Müqavilə</li>
                    <li>Nomenklatura</li>
                    <li>Nomenklatura vahid</li>
                    <li>Bank hesabları</li>
                    <li>Baş kitab</li>
                    <li>Mühabirləşmə</li>
                    <li>Balans</li>
                    <li>Hesab qalıqları</li>
                    <li>Balans</li>
                    <li>Hesab qalıqları</li>
                    <li>Satış</li>
                    <li>Alış</li>
                    <li>Bank</li>
                    <li>Kassa</li>
                    <li>Əmək haqqı</li>
                    <li>Analitik hesabatlar</li>
                    <li>Hesablar planı</li>
                    <li>Cari bağlama</li>
                    <li>İcmal hesabatı **</li>
                </ul>

                <motion.div className="container"
                            animate={controls}
                            variants={isMobile ? reveal : null}
                            transition={{delay: 0.1, duration: 0.4}}
                >
                    <div className="card">
                        <div className="Front">
                            <h2>
                                Mikro sahibkar
                            </h2>

                            <div className="radio-btns">
                                <div className="btn">
                                    <input
                                        onClick={() => setSahibkar(!sahibkar)}
                                        defaultChecked={sahibkar === false}
                                        name="status"
                                        type="radio"
                                        id="rad1"
                                        value="fiziki"/>
                                    <label htmlFor="rad1">Fiziki</label>


                                </div>

                                <div className="btn">
                                    <input
                                        onClick={() => setSahibkar(!sahibkar)}
                                        type="radio"
                                        id="rad2"
                                        name="status"
                                        value="huquqi"/>
                                    <label htmlFor="rad2">Hüquqi </label>


                                </div>
                            </div>


                        </div>
                        <div className="bottom">

                            <div className="qiymetler">
                                <div className="ayliq">
                                    <p>Aylıq: </p>
                                    <span>{sahibkar ? `15 ₼` : `10 ₼`}</span>

                                </div>
                                <div className="ayliq">
                                    <p>İllik: </p>
                                    <span>{sahibkar ? `90 ₼` : `60 ₼`}</span>
                                </div>


                            </div>
                            <div className="sahibkarliq-subyektleri">
                                <h3 className="subyektler">Mikro sahibkarlıq subyektləri:</h3>
                                <ul>
                                    <li>İşçi sayı 1-10 nəfər</li>
                                    <li>İllik gəlir &lt;  200.000</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                    <div className="card">
                        <div className="Front">
                            <h2>
                                Kiçik sahibkar
                            </h2>

                        </div>
                        <div className="bottom">

                            <div className="qiymetler">
                                <div className="ayliq">
                                    <p>Aylıq: </p>
                                    <span>40 &#8380; </span>
                                </div>
                                <div className="ayliq">
                                    <p>İllik: </p>
                                    <span>240 &#8380;</span>
                                </div>


                            </div>
                            <div className="sahibkarliq-subyektleri">
                                <h3 className="subyektler">Kiçik sahibkarlıq subyektləri:</h3>
                                <ul>
                                    <li>İşçi sayı 11-50 nəfər</li>
                                    <li>200.000 &le; İllik gəlir &le; 3.000.000</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                    <div className="card">
                        <div className="Front">
                            <h2>
                                Orta sahibkar
                            </h2>

                        </div>
                        <div className="bottom">

                            <div className="razilasma qiymetler">
                                <div className="ayliq">
                                    <span className="razilasma">Qarşılıqlı razılaşma</span>
                                </div>



                            </div>
                            <div className="sahibkarliq-subyektleri">
                                <h3 className="subyektler">Orta sahibkarlıq subyektləri:</h3>
                                <ul>
                                    <li>İşçi sayı 51-250 nəfər</li>
                                    <li>3.000.000 &le; İllik gəlir &le; 30.000.000</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                    <div className="card">
                        <div className="Front">
                            <h2>
                                İri sahibkar
                            </h2>

                        </div>
                        <div className="bottom">


                            <div className="razilasma qiymetler">
                                <div className="ayliq">
                                    <span  className="razilasma">Qarşılıqlı razılaşma</span>
                                </div>



                            </div>
                            <div className="sahibkarliq-subyektleri">
                                <h3 className="subyektler">İri sahibkarlıq subyektləri:</h3>
                                <ul>
                                    <li>İşçi sayı &ge; 251</li>
                                    <li>İllik gəlir &ge; 30.000.000</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </motion.div>
                <motion.p className="ps"
                          animate={controls}
                          variants={isMobile ? reveal : null}
                          transition={{delay: 0.1, duration: 0.4}}
                >
                    <span>* Hər bir əlavə rəhbər istifadəçi üçün xidmət haqqı - 40 AZN / illik xidmət haqqı - 240 AZN;</span>
                    <span>* Hər bir əlavə işçi istifadəçi üçün xidmət haqqı - 20 AZN / illik xidmət haqqı - 120 AZN</span>
                    <span className="sinaq">* 30 günlük sınaq müddəti pulsuzdur</span>
                    <span>** İcmal hesabatı üçün ödəniş - 40 AZN</span>
                </motion.p>

            </div>
        </div>
    )
        ;
}

export default Tarifler;