import "../../assets/styles/faq.scss";
import Accordion from "../../components/Front/FAQ/Accordion";
import ApplicationForm from "../../components/Front/FAQ/ApplicationForm";
import Navbar from "../../components/Front/Navbar";
import Footer from "../../components/Front/Footer";

function Faq(props) {
  return (
    <>
      <Navbar />
      <div className="Faq">
        <div className="container">
          <Accordion />
          <ApplicationForm />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Faq;
